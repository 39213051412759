import styled from '@emotion/styled';
import React from 'react';
import { IconClose24 } from 'components/base/Icon';
import { useIsKakaoPrivateBrowser } from 'hooks/useIsKakaoPrivateBrowser';
import { black } from 'styles';
import { isShowKakaoCloseButton } from 'util/kakao_utils';

/**
 * 카톡 전용브라우저의 경우 우측 상단 닫기버튼이 있어 분기처리 필요...
 * 카카오톡 전용 브라우저 대응 뒤로가기 버튼
 * @component
 */
const KakaoBrowserBackButton: React.FC = () => {
  const is_kakao_talk_private_broswer = useIsKakaoPrivateBrowser();

  const onAosKakaoBrowserClose = () => {
    location.href = 'app://closeWebview';
  };

  // if (!is_kakao_talk_private_broswer || typeof window === 'undefined') {
  //   return null;
  // }
  // if (isShowKakaoCloseButton()) {
  //   return <IosBackArea />;
  // }

  return <CloseIcon onClick={onAosKakaoBrowserClose} />;
};

export default KakaoBrowserBackButton;

const IosBackArea = styled.span`
  width: 34px;
`;

const CloseIcon = styled(IconClose24)`
  color: ${black};
  margin-left: 12px;
  width: 24px;
  height: 24px;
`;
